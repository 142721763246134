export default (livewireComponent) => ({

    loading: false,
    showForm: livewireComponent.entangle('showForm'),
    showFailuresList: false,
    showCustomSeverityList: false,
    showViolationsList: false,
    showSolutionsList: false,
    customSeverities: ["Critical", "Very High", "High", "Moderate", "Low"],

    failureRequired: false,
    customSeverityRequired: false,
    violationRequired: false,
    solutionRequired: false,
    listenerActive: false,

    styles: livewireComponent.entangle('styles'),
    coordinates: livewireComponent.entangle('coordinates'),
    selector: livewireComponent.entangle('elementSelector'),

    selectedFailure: livewireComponent.entangle('selectedFailure').live,
    selectedViolation:livewireComponent.entangle('selectedViolation'),
    selectedSolutions: livewireComponent.entangle('selectedSolutions'),

    selectedFailureTitle: livewireComponent.entangle('selectedFailureTitle'),
    selectedCustomSeverity: livewireComponent.entangle('selectedCustomSeverity').live,
    selectedViolationTitle: livewireComponent.entangle('selectedViolationTitle'),
    selectedSolutionTitle: livewireComponent.entangle('selectedSolutionTitle'),

    failures: livewireComponent.entangle('failure_techniques'),
    violations: livewireComponent.entangle('violations'),
    solutions: livewireComponent.entangle('solutions'),

    notes: livewireComponent.entangle('notes'),
    steps_to_visible: livewireComponent.entangle('steps_to_visible'),

    highlightedFailure: null,
    highlightedCustomSeverity: null,
    highlightedViolation: null,

    search: '',

    isDragging: false,
    offset: { x: 0, y: 0 },

    boundScreenshotReceived: null,

    init() {
        let iframeContainerEl = document.getElementById('visualIframeContainer');
        let visualIframeEl = document.getElementById('visualIframe');

        var pos1 = 0,
            pos2 = 0,
            pos3 = 0,
            pos4 = 0

        this.$watch('showForm', value =>
            {
                if (value) {
                    // Make the DIV element draggable:
                    var elmnt = document.getElementById('manualIssueForm' + 'Header');
                    elmnt.onmousedown = dragMouseDown;

                    let iframeRect = iframe.getBoundingClientRect();

                    let top = this.coordinates.top - 603;
                    let left = this.coordinates.left;

                    // Check if the form is above the iframe
                    if (top < iframeRect.top) {
                        top = iframeRect.top;
                    }


                    // Check if the form is to the left of the iframe
                    if (left < iframeRect.left) {
                        left = iframeRect.left;
                    }

                    // Check if the form is to the right of the iframe
                    if (left + 432 > iframeRect.right) {
                        left = iframeRect.right - 432;
                    }

                    this.styles = {
                        top: top + 'px',
                        left: left + 'px',
                    };

                    setTimeout(() => {
                        // Wait until the form is fully ready in the DOM
                        this.$el.focus();
                        document.querySelector('[key = "failuresListSelect"]').focus();
                    }, 300);

                    // Disable iframe keyboard navigation when modal is open, since we're not locking the tab
                    if(iframeContainerEl && visualIframeEl) {
                        iframeContainerEl.setAttribute('tabindex', '-1');
                        visualIframeEl.setAttribute('tabindex', '-1');
                    } else {
                        console.warn('Failed to set iframe attributes; elements not found!');
                    }

                } else {
                    this.styles = {};
                    if(iframeContainerEl && visualIframeEl) {
                        iframeContainerEl.setAttribute('tabindex', '0');
                        visualIframeEl.removeAttribute('tabindex');
                    } else {
                        console.warn('Failed to reset iframe attributes; elements not found!');
                    }
                }

                function dragMouseDown(e) {
                    e = e || window.event;
                    e.preventDefault();
                    pos3 = e.clientX;
                    pos4 = e.clientY;

                    elmnt = document.getElementById('manualIssueForm');

                    document.onmouseup = closeDragElement;
                    document.onmousemove = elementDrag;
                }

                function elementDrag(e) {
                    e = e || window.event;
                    e.preventDefault();
                    pos1 = pos3 - e.clientX;
                    pos2 = pos4 - e.clientY;
                    pos3 = e.clientX;
                    pos4 = e.clientY;

                    // set the element's new position:
                    elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
                    elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
                    
                    this.styles = {
                        top: elmnt.style.top,
                        left: elmnt.style.left
                    };

                    clearTimeout(this._debounceTimer);
                    this._debounceTimer = setTimeout(() => {
                        livewireComponent.set('styles', this.styles);
                    }, 1000);
                }

                function closeDragElement() {
                    document.onmouseup = null;
                    document.onmousemove = null;
                }
            },
        );
        this.$watch('selectedSolutions', value => {
            if (value.length > 0) {
                this.solutionRequired = false;
                this.selectedSolutionTitle = value.map(solution => solution).join(', ');
            } else if(this.selectFailure != '') {
                this.selectedSolutionTitle = 'Select Recommended Solutions';
            }
        });
        this.boundScreenshotReceived = this.screenshotReceived.bind(this);

        window.Livewire.on('AnnotationOff', () => {
            this.cancel();
        });
      
        window.Livewire.on('closeManualIssueForm', () => {
            if(this.showForm) {
                this.cancel();
            }
        });
    },

    selectFailure(failure) {
        this.failureRequired = false;
        this.showFailuresList = false;
        this.selectedFailure = failure._id;
        this.selectedFailureTitle = failure.title;
    },

    selectCustomSeverity(severity) {
        this.customSeverityRequired = false;
        this.showCustomSeverityList = false;
        this.selectedCustomSeverity = severity;
    },

    selectViolation(violation) {
        this.violationRequired = false;
        this.selectedViolation = violation._id;
        this.selectedViolationTitle = violation.num + ': ' + violation.handle;
    },

    submit(){
        livewireComponent.call('validateForm');

        this.loading = true;
        this.failureRequired = false;
        this.violationRequired = false;
        this.solutionRequired = false;

        if (!this.selectedFailure) {
            this.failureRequired = true;
            this.loading = false;
            return;
        }

        if (!this.selectedCustomSeverity) {
            this.customSeverityRequired = true;
            this.loading = false;
            return;
        }

        if(this.solutions.length != 0 && this.selectedSolutions.length == 0) {
            this.solutionRequired = true;
        }

        if(this.violations.length != 0 && !this.selectedViolation) {
            this.violationRequired = true;
        }

        if(this.failureRequired || this.violationRequired || this.solutionRequired ) {
            this.loading = false;
            return;
        }

        // send request to iframe to get screenshot
        iframe.contentWindow.postMessage(
            {
                function: "getScreenshot",
                selector: this.selector,
            },
            site_domain
        );

        if(!this.listenerActive) {
            window.addEventListener("message", this.boundScreenshotReceived, true);
            this.listenerActive = true;
        }

    },

    callCreateIssue: function() {
        livewireComponent.call('createManualIssue').then(($data) => {
            if($data != false) {
                this.restDefaultBehavior()
                window.Livewire.dispatch('toggleIssuesLayer')
                window.Livewire.dispatch('cancelManualIssue')
                this.restForm()
            } else {
                this.loading = false;
                livewireComponent.call('flashMessage')
            }
        }).catch((error) => {
            console.error('Could not create issue', error)
        })
    },

    screenshotReceived(event) {
        if (event.origin == site_domain) {
            if (event.data.msg == "screenshot") {
                window.removeEventListener("message", this.boundScreenshotReceived, true);
                this.listenerActive = false;
                livewireComponent.call('saveScreenshot', event.data).then(() => {
                    this.callCreateIssue();
                }).catch((error) => {
                    console.error('Could not save screenshot', error)
                    this.callCreateIssue();
                });
            }
        }
    },


    cancel(){
        this.restForm()
        this.restDefaultBehavior()
        window.Livewire.dispatch('toggleIssuesLayer')
        window.Livewire.dispatch('cancelManualIssue')
    },

    restDefaultBehavior() {
        iframe.contentWindow.postMessage(
            {
                function: "defaultBehavior",
            },
            site_domain
        );
    },

    restForm() {
        this.failureRequired = false;
        this.violationRequired = false;
        this.solutionRequired = false;

        this.violations = [];
        this.solutions = [];
        this.search = '';

        this.selectedFailure = '';
        this.selectedFailureTitle = 'Select a Failure';
        this.selectedCustomSeverity = 'Select a Failure First';
        this.selectedViolation = '';
        this.selectedViolationTitle = 'Select a Failure First';
        this.selectedSolutions = [];
        this.selectedSolutionTitle = 'Select a Failure First';

        this.notes = '';
        this.steps_to_visible = '';

        this.loading = false;
        this.showForm = false;
    }

});
